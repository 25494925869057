/* You can add global styles to this file, and also import other style files */
body {
  background: #f0f0f0;
  font-weight: 400;
  padding-bottom: 50px;
  padding-top: 50px;
}

video {
  &:focus {
    outline: none;
  }
}

.d-b {
  display: block !important;
}

.d-ib {
  display: inline-block;
}

.dropdown-menu {
  .divider {
    margin: 4px 0;
  }

  > li {
    > span {
      clear: both;
      color: #789;
      display: block;
      font-weight: normal;
      line-height: 1.5;
      padding: 3px 20px;
      white-space: nowrap;
    }
  }
}

.form-control {
  border-radius: 0;
}

.btn {
  border-radius: 2px;
}

.m-l-xs {
  margin-left: 5px !important;
}

.m-r-xs {
  margin-right: 5px !important;
}

.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.z-depth-1a {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.well-z {
  background: #fcfcfc;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  box-shadow: 0 1px 10px #a7a7a7, inset 0 1px 0 #fff;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}

.table {
  .icon-dots-three-horizontal {
    color: #999;
    font-size: 18px;
  }

  .icon-dots-three-vertical {
    color: #555;
    font-size: 18px;
  }
}

.table-no-border {
  > tbody > tr > td,
  > tbody > tr > th {
    border-top: 0 !important;
  }
}

legend {
  color: #555;
}

.btn-wide {
  min-width: 100px;
}

.hr-divider-md {
  font-size: 16px;
  font-variant: small-caps;
  text-transform: initial;
}

.hr-divider-lg {
  font-size: 18px;
  font-variant: small-caps;
  text-transform: initial;
}

.well {
  &.well-capped {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
  }

  .hr-divider-content {
    background-color: transparent;
  }
}

.narrow {
  white-space: nowrap;
  width: 10px;
}

.wide-in-one-line {
  max-width: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-1x {
  font-size: 20px !important;
  padding-bottom: 0px !important;
  padding-top: 4px !important;
}

.thumbnail-image {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  .image {
    .progress {
      background-color: transparent;
      // background-color: rgba(255, 255, 255, 0.2);
      border-radius: 0;
      bottom: 0;
      box-shadow: none;
      height: 4px;
      left: 1px;
      position: absolute;
      right: 1px;
      z-index: 1002;
      margin-bottom: 4px;

      .progress-bar {
        // background-color: #333;
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: none;
      }
    }

    .progress:nth-child(2) {
      .progress-bar {
        background-color: #fff;
        box-shadow: none;
      }
    }
  }

  &.selected {
    border: 1px solid #64bd63;
  }
}

.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-100 {
  width: 100%;
}

.bs-datepicker-invisible {
  position: absolute !important;
  visibility: hidden;
  z-index: -1000;
}

.bs-datepicker {
  z-index: 10;
}

.opacity-0 {
  opacity: 0;
}

.loading {
  opacity: 0.2;
  filter: blur(2px);
}

.form-spaced {
  .form-group {
    margin-bottom: 25px !important;
  }
}

.font-variant-normal {
  font-variant: normal !important;
}

.cursor-initial {
  cursor: initial !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container-main {
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 10px #a7a7a7, inset 0 1px 0 #fff;
}

.popover-title.popover-header {
  text-align: center;
}

.pdf-viewer {
  border: none;
  min-height: 1200px;
  width: 100%;
}

.btn-link.text-danger {
  color: #e64759;
}

.preserve-wrap {
  white-space: pre-wrap;
}

.ng-select {
  .ng-select-container {
    border-radius: 0;
  }
}
